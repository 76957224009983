import isClientSide from "@/utils/isClientSide";

export function dispatchNewEvent(event, detail = null) {
  const triggerEvent = new window.CustomEvent(event, { detail });
  return document.body.dispatchEvent(triggerEvent);
}

export function updateDataLayer(event, data) {
  if (!event || !data) return false;
  window.jlData = {
    ...window.jlData,
    ...data,
  };
  return true;
}

export const onBootstrapperReady = callback => {
  if (isClientSide()) {
    const isBootstrapperReady = setInterval(() => {
      if (window.Bootstrapper && window.Bootstrapper.dataStateChangeReady === true) {
        callback();
        clearInterval(isBootstrapperReady);
      }
    }, 100);
  } else {
    callback();
  }
};

export const analyticsFactory = (pathname, data = {}) => {
  const pageName = ("customer-services" + pathname).replace(/^(\/)/, "").replace(/\//g, ":");
  const analytics = {};
  const { error, customer, pageInfoData, ...pageData } = data;

  if (error) {
    analytics.error = error;
  }
  if (customer) {
    analytics.customer = customer;
  }

  analytics.page = {
    locale: {
      country: "uk",
      currency: "gbp",
      language: "en-gb",
    },
    pageInfo: {
      breadCrumb: pageName.split(":").map(value => ({ value, clickable: 1 })),
      pageName: `jl:${pageName}`,
      pageType: "content",
      site: window.location.host,
      pageCanonicalUrl: window.location.toString(),
      ...pageInfoData,
    },
    ...pageData,
  };

  return analytics;
};

export default function analyticsTrigger(event, pathname, page, action) {
  const data = analyticsFactory(pathname, page);
  updateDataLayer(event, data);
  onBootstrapperReady(() => dispatchNewEvent(event, { action }));
}
