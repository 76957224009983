import ESIPage from "@/components/esi-page/ESIPage";
import { capitalize } from "lodash";

function generateMetadata(params) {
  const title = params?.slug?.[0]?.split("-").map(capitalize).join(" ");
  return {
    title: title || "About Us",
    description:
      "At John Lewis & Partners, we're more than employees we're owners. That's why we're all called Partners.",
  };
}

function Page() {
  return <ESIPage />;
}

Page.generateMetadata = generateMetadata;

export default Page;
