import React, { useEffect } from "react";
import analyticsTrigger from "@/utils/analytics/trigger/analyticsTrigger";
import ESIInclude from "@JohnLewisPartnership/jl-esi-components";
import { usePathname } from "next/navigation";

function ESIPage() {
  const pathname = usePathname();
  useEffect(() => {
    analyticsTrigger("dataStateChange", pathname, {}, "pageload");
  }, []);
  return (
    <ESIInclude
      id="content"
      src={`/content-renderer/customer-services${pathname}?includeCoreJS=false&includePolyfills=false&isJoyfullyBold=true`}
    />
  );
}

export default ESIPage;
